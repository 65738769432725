<template>
  <div>
    <h1 class="mt-5 mb-12 text-center">Admin Dashboard</h1>
    <v-row style="max-width: 800px" class="mx-auto">
      <v-col>
        <v-card class="mx-auto" tile elevation="12">
          <v-list flat>
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group color="primary">
              <v-list-item :to="{ path: '/admin/organizations' }">
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Manage Organizations</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/advisors' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Manage Advisors</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/prospects' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Manage Prospects</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/reporting' }">
                <v-list-item-icon>
                  <v-icon>mdi-file-chart</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Reporting</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/resources' }">
                <v-list-item-icon>
                  <v-icon>mdi-library-shelves</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Resources</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" tile elevation="12">
          <v-list flat>
            <v-list-item-group color="primary">
              <v-list-item :to="{ path: '/admin/promos' }">
                <v-list-item-icon>
                  <v-icon>mdi-ticket-percent</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Promos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item :to="{ path: '/admin/import' }">
                <v-list-item-icon>
                  <v-icon>mdi-database-import</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Importing</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/report-editor' }">
                <v-list-item-icon>
                  <v-icon>mdi-text-box-edit-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Report Editor</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/prospect-invite' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Prospect Invite</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="{ path: '/admin/prospect-invite' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Prospect Invite</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/admin/report-templates' }">
                <v-list-item-icon>
                  <v-icon>mdi-wrench-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Report Templates</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
